/* eslint-disable */

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {default as MuiLink} from '@mui/material/Link';
import { loginSpecProject } from '../../services/AuthServiceSpecProject';
import { useSnackbar } from 'notistack';
import { Store } from '../../contexts/Store';
// import { useRouter } from 'next/router';
import { useAuthSpecProjects } from '../../hooks/useAuthSpecProjects';
import { Link, useNavigate } from 'react-router-dom';


export default function LoginForm() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const { state, dispatch } = React.useContext(Store);
    // const router = useRouter();
    const navigate = useNavigate()

    const {login} = useAuthSpecProjects();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const userData = {
            email: data.get('email'),
            code: data.get('code'),
        }

        if(!userData.email || !userData.code) {
            enqueueSnackbar('Заполните все поля', {variant: "error"});

            return
        }

        try {
            await login(userData)
            enqueueSnackbar('Вы успешно вошли в кабинет', {variant: "success"});

            navigate('/ad-specprojects')

        } catch (err) {
            console.log('error', err.code);
            enqueueSnackbar(
                err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message,
                {variant: "error"}
            );

        } finally {
            // setError(false)
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    // marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Вход
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
   
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                                autoComplete="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="code"
                                label="Код"
                                type="code"
                                id="code"
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Войти
                    </Button>
                     <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/registr-specprojects" >
                                <MuiLink variant="body2" sx={{ cursor: "pointer" }}>
                                    Еще не регистрировались? Регистрация
                                </MuiLink>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}
