/* eslint-disable react/jsx-filename-extension */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
// import { useRouter } from 'next/router';
import { Container, Typography, Box, Avatar } from '@mui/material';
import Modal from 'react-modal';

import { styled } from '@mui/material/styles';
import MainLayout from '../MainLayout';
// import InnerBanner from '../components/InnerBanner';
import { getAllSpeakers } from '../../services/Speakers.service';
import Speaker from '../../components/Speaker';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import { getAllFaqMain } from '../../services/FAQMain.service';
import ModalComponent from '../../components/Modal';
import InnerBanner from '../../components/InnerBanner';
import { getAllBanners } from '../../services/Banner.service';
import { useParams } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader';

Modal.setAppElement('#root');

const A = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    cursor: 'pointer',
}));

const CustomModule = styled(Modal)(({ theme }) => ({
    position: 'fixed',
    zIndex: '1300',
    inset: '0',

    border: '1px  solid rgb(204, 204, 204)',

    background: ' rgb(255, 255, 255)',
    overflow: 'auto',
    borderRadius: 4,

    outline: 'none',
    padding: '20px',
}));

export default function SpeakersPage() {
    // const router = useRouter();
    const params = useParams();

    const [speakers, setSpeakers] = useState([])
    const [speaker, setSpeaker] = useState({});
    const [banner, setBanner] = React.useState();
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)

    const [openModal, setOpenModal] = React.useState(false);

    const handleOpen = () => {
        setOpenModal((prev) => !prev);
    };

    const handleChangeSpeaker = (speaker) => {
        setSpeaker(speaker);
        setOpenModal((prev) => !prev);
    };

    const fetchData = async () => {
      try {
        setLoader(true)
        const speakersResponse = await getAllSpeakers();
        setSpeakers(speakersResponse)

        const banner = await getAllBanners();
        setBanner(banner);

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

      } catch (error) {
          console.log(error);
      } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}
                <InnerBanner banner={banner} />
                <ModalComponent data={speaker} handleOpen={handleOpen} openModal={openModal} />

                <Container>
                    <Box my={4}>
                        <Typography variant="h4" component="h1" gutterBottom align="left" sx={{ my: 8 }}>
                            Спикеры Congress.by
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        {speakers?.map((speaker) => (
                            <Box
                                key={speaker._id}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    pr: 2,
                                    mb: 4,
                                    minWidth: { xs: '100%', md: '25%' },
                                    width: { xs: '50%', md: '25%' },
                                }}
                            >
                                <A onClick={() => handleChangeSpeaker(speaker)}>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={speaker?.photoUrl}
                                            sx={{ width: '200px', height: '200px', mb: 2, alignItems: 'center' }}
                                        />
                                        <Typography variant="h6" component="h1" gutterBottom align="center">
                                            {speaker?.fio}
                                        </Typography>
                                    </Box>
                                </A>
                            </Box>
                        ))}
                    </Box>
                </Container>

                {/* <CustomModule
                    style={{ zIndex: 999 }}
                    isOpen={!!router.query.id} // The modal should always be shown on page load, it is the 'page'
                    onRequestClose={() => router.push('/speakers')}
                    contentLabel="Post modal"
                >
                    <Speaker id={router.query.id} pathname={router.pathname} speaker={speaker} />
                </CustomModule> */}
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
}
