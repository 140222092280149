/* eslint-disable */
import { useEffect, useContext } from 'react';
// import { useRouter } from 'next/router';
import { useAuthSpecProjects } from './useAuthSpecProjects';
import { Store } from '../contexts/Store';
import FullPageLoader from '../components/FullPageLoader';
import { useNavigate } from 'react-router-dom';

export default function PrivateRoute({ children }) {
    const navigate = useNavigate();
    const { logout, checkAuth } = useAuthSpecProjects();
    const { state } = useContext(Store);

    // const pathIsProtected = protectedRoutes.indexOf(router.pathname) !== -1;

    useEffect(() => {
        async function checkToken(){
            await checkAuth()
        }

        // if(pathIsProtected) {
        //     checkToken()
        // }
       
    }, []);


    useEffect(() => {
        if (!state.isLoading && !state.isAuth) {

            // Redirect route, you can point this to /login
            navigate('/login-specprojects');
        }
    }, [state.isLoading, state.isAuth]);

    if ((state.isLoading || !state.isAuth)) {
        return <FullPageLoader />;
    }

    return children;
}
