/* eslint-disable */
import React from 'react';
import {
    Paper,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        // backgroundColor: theme.palette.common.black,
        // color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        backgroundColor: theme.palette.primary.light,
        paddingTop: '15px',
        paddingBottom: '15px',
    },

    '&:last-child tr, &:last-child th': {
        marginBottom: 30,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        // backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

const ProgramTable = ({ title, data }) => {
    return (
        <div>
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 5 }}>
                {title}
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 700 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Время</StyledTableCell>
                            <StyledTableCell>Тема</StyledTableCell>
                            <StyledTableCell>Лекторы</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((item) => {
                            if (item.time.length !== 0 || item.lectors.length !== 0) {
                                return (
                                    <StyledTableRow key={item.time}>
                                        <StyledTableCell component="th" scope="row">
                                            {item.time}
                                        </StyledTableCell>
                                        <StyledTableCell>{item.title}</StyledTableCell>
                                        <StyledTableCell
                                            dangerouslySetInnerHTML={{ __html: item.lectors }}
                                            sx={{
                                                lineHeight: '1',
                                            }}
                                        />
                                    </StyledTableRow>
                                );
                            }

                            return (
                                <StyledTableRow key={item.time}>
                                    <StyledTableCell colSpan={3}>
                                        <Typography variant="h6" sx={{ fontWeight: 700, mb: 1, mt: 1 }}>
                                            {item.title}
                                        </Typography>
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default ProgramTable;
