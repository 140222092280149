/* eslint-disable */
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
// import ArticleCard from '../ArticleCard';
// import EventCard from '../EventCard';
// import theme from '../../theme';
import RecommendationCard from '../RecommendationCard';

const RecommendationLibrary = ({ data }) => {
    return (
        <Box
            sx={{
                pb: 3,
                py: 8,
                backgroundColor: 'hsl(0deg 0% 97%);',
            }}
        >
            <Container>
                <Typography
                    variant="h4"
                    sx={{
                        mb: 4,
                         mx: 1
                    }}
                >
                    Мы рекомендуем
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' },
                        my: 8,
                        justifyContent: 'space-between',

                    }}
                >
                    {data.map((article) => (
                        <Box
                            key={article._id}
                            sx={{
                                position: 'relative',

                                minWidth: { xs: '100%', md: '33%' },
                                width: { xs: '50%', md: '33%' },
                                mx: 1
                            }}
                        >
                            <RecommendationCard
                                url="article"
                                item={article.item}
                                onModel={article.onModel}
                                width="345px"
                            />
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default RecommendationLibrary;
