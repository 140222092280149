/* eslint-disable */
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import theme from '../../theme';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.primary.light,
    borderBottom: `1px solid ${theme.palette.divider}`,
    // flexDirection: 'row-reverse',

    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    backgroundColor: theme.palette.primary.light,
}));

const MainFaq = ({ faq }) => {
    const [expanded, setExpanded] = React.useState('');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
    return (
        <Box
            sx={{
                mt: 10,
                mb: 8,
            }}
        >
            <Typography
                variant="h4"
                align="center"
                sx={{
                    // fontWeight: 700,
                    mb: 5,
                }}
            >
                Ответы на частые вопросы
            </Typography>
            {faq && faq.map((item, index) => (
                <Accordion
                    expanded={expanded === `panel${index + 1}`}
                    onChange={handleChange(`panel${index + 1}`)}
                    key={item._id}
                >
                    <AccordionSummary
                        aria-controls="panel1d-content"
                        id="panel1d-header"
                        expandIcon={
                            expanded === `panel${index + 1}` ? (
                                <CloseIcon fontSize="large" />
                            ) : (
                                <KeyboardArrowDownIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />
                            )
                        }
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{
                                mt: 2,
                                mb: 2,
                                fontWeight: 500,
                            }}
                        >
                            {item.question}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box
                            dangerouslySetInnerHTML={{ __html: item.answer }}
                            sx={{
                                p: 1,
                            }}
                        />
                    </AccordionDetails>
                </Accordion>
            ))}
        </Box>
    );
};

export default MainFaq;
