import React from 'react';
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const Partners = ({ partners }) => {
    return (
        <>
            <Typography variant="h4" sx={{ fontWeight: 700, mb: 5, mt: 8 }}>
                Партнеры мероприятия
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                }}
            >
                {partners.map((partner) => (
                    <Box sx={{ margin: '10px 10px' }}>
                        <Link to={partner.link}>
                            <img src={partner.logoUrl} width="250px" height="100%" />
                        </Link>
                    </Box>
                ))}
            </Box>
        </>
    );
};

export default Partners;
