import { get } from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAllVideos = async (page, category) => {
    const params = {
        params: {
            type: 'all',
            page,
            category,
        },
    };
    const response = await get(`libraries/videos`, params);
    console.log(response.data);
    return response.data.allVideos;
};

export const getVideo = async (id) => {
    const response = await get(`libraries/videos/${id}`);
    return response.data;
};
