/* eslint-disable */
import React from 'react';
import { Box, Button, Card, CardContent, CardMedia, IconButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';

const CustomGreenButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    padding: '10px 30px',
    marginBottom: '15px',
    width: '100%',
    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const A = styled('a')({
    textDecoration: 'none',
    width: '100%',
});

const NextLink = styled(Link)({
    display: 'flex',
    alignItems: 'flex-start',
    width: '100%',
});


const LibraryCard = ({ data, link }) => {
    return (
        <Card sx={{ display: 'flex', width: '100%', flexDirection: { sm: 'row', xs: 'column' } }}>
            <CardMedia component="img" sx={{ width: { sm: 340, xs: '100%' } }} image={data.imgUrl} alt={data.title} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography component="div" variant="h5">
                        {data.title}
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        color="text.secondary"
                        dangerouslySetInnerHTML={{ __html: data.description }}
                        sx={
                            {
                                // p: 1,
                            }
                        }
                    />
                </CardContent>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'flex-end',
                        width: '100%',
                        padding: { xs: '16px', md: '0' },
                    }}
                >
                    <NextLink to={`../${link}/${data._id}`}>
                        {/* <A> */}
                            <CustomGreenButton
                                sx={{ maxWidth: { xs: '100%', md: '200px' }, marginLeft: { xs: '0px', md: '16px' } }}
                            >
                                Подробнее
                            </CustomGreenButton>
                        {/* </A> */}
                    </NextLink>
                </Box>
            </Box>
        </Card>
    );
};

export default LibraryCard;
