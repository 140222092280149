import { get } from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAllPharmnews = async (page, category) => {
    const params = {
        params: {
            type: 'all',
            page,
            category,
        },
    };
    const response = await get(`libraries/pharmnews`, params);
    return response.data.allPharmnews;
};

export const getPharmnews = async (id) => {
    const response = await get(`libraries/pharmnews/${id}`);
    return response.data;
};
