/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/prefer-default-export */
import client from '../http';

export const loginSpecProject = async (data) => {
    const response = await client.post('/auth-spec-project/login', data);
    return response.data;
};

export const registrationSpecProject = async (data) => {
    const response = await client.post('/auth-spec-project/registration', data);
    return response.data;
};

export const logoutSpecProject = (fio, email) => {
    const response = client.post('/', { fio, email });
    return response.data;
};
