// eslint-disable-next-line import/prefer-default-export
export const getMonth = (month) => {
    const monthes = [
        'Января',
        'Февраля',
        'Марта',
        'Апреля',
        'Мая',
        'Июня',
        'Июля',
        'Августа',
        'Сентября',
        'Октября',
        'Ноября',
        'Декабря',
    ];
    return monthes[month - 1];
};
