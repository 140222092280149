/* eslint-disable */
import React, { useEffect, useState } from 'react';
// import { useRouter } from 'next/router';
import { Box, CardContent, Chip, Container, Typography, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import moment from 'moment';
import InnerBanner from '../../components/InnerBanner';
import { getEvent } from '../../services/Events.service';
import theme from '../../theme';
import { getMonth } from '../../utils/getMonth';
import AdressIcon from '../../components/SvgImages/adressIcon';
import CalendarIcon from '../../components/SvgImages/calendarIcon';
import TimeIcon from '../../components/SvgImages/timeIcon';
import AboutEvent from '../../components/AboutEvent';
import ProgramTable from '../../components/ProgramTable';
import LectorsSlider from '../../components/LectorsSlider';
import Partners from '../../components/Partners';
import MainFaq from '../../components/FAQMain';
import { getAllFaqMain } from '../../services/FAQMain.service';
import SubscribeNews from '../../components/SubscribeNews';
import YouTubeVideo from '../../components/YouTubeVideo';
import WebinarRuContent from '../../components/WebinarRuContant';
import Forecast from '../../components/Forecast';
import MainLayout from '../MainLayout';
import { Link, useParams } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader';

const CustomChip = styled(Chip)({
    fontWeight: 900,
});

const Atag = styled('a')({
    textDecoration: 'none',
});

const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 30,
    padding: '10px 15px',
    '&:hover': {
        backgroundColor: theme.palette.secondary.light,
    },
}));

const CustomGreenButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    maxWidth: '400px',
    padding: '10px 15px',

    '&:hover': {
        backgroundColor: theme.palette.secondary.main,
    },
}));

const CustomButtonBlue = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.light,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 30,
    padding: '10px 15px',
    '&:hover': {
        backgroundColor: theme.palette.primary.main,
    },
}));

const CustomColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 0px',
});

const EventsInnerPage = () => {
    const techRef = React.useRef(null);
    const certhRef = React.useRef(null);

    const [event, setEvent] = useState([])
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)

    const params = useParams();

    const techScroll = () => techRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    const certScroll = () => certhRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const monthVal = moment(event.date, 'YYYY/MM/DD').format('M');
    const month = getMonth(monthVal);

    const fetchEvent = async () => {
        try {
            setLoader(true)

            const event = await getEvent(params.id);
            setEvent(event)

            const respFaq = await getAllFaqMain();
            setFaq(respFaq)

        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchEvent()
    }, []);

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}

                <InnerBanner>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: { xs: '100%', md: '70%' },
                                minWidth: '70%',
                            }}
                        >
                            <Box>
                                {event?.typeEvent?.map((type) => (
                                    <CustomChip
                                        label={type.title}
                                        variant="outlined"
                                        sx={{
                                            mr: 2,
                                            mb: 2,
                                            color: theme.palette.primary.light,
                                            borderColor: theme.palette.primary.light,
                                        }}
                                    />
                                ))}
                            </Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '24px', md: '40px' },
                                    color: theme.palette.primary.light,
                                }}
                            >
                                {event.title}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '100%', md: '70%' },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: { xs: 'flex-start', md: 'center' },
                                alignSelf: 'center',
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    color: theme.palette.primary.light,
                                    fontWeight: 700,
                                    mb: 5,
                                    marginTop: { xs: '10px', md: '0px' },
                                    fontSize: { xs: '20px', md: '32px' },
                                }}
                            >
                                <Moment format="DD">{event.date}</Moment> {month}{' '}
                                <Moment format="YYYY">{event.date}</Moment>
                            </Typography>
                            {event.linkToEvent && event.linkToEvent.linkToEvent && (
                                <Link to={event.linkToEvent.linkToEvent}>
                                    <Atag target="_blank">
                                        <CustomButton variant="contained">
                                            <Typography
                                                // variant="h6"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: { xs: '12px', md: '22px' },
                                                }}
                                            >
                                                Зарегистрироваться
                                            </Typography>
                                        </CustomButton>
                                    </Atag>
                                </Link>
                            )}
                        </Box>
                    </Box>
                </InnerBanner>
                <Container>
                    <CustomColumn>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            {event?.directionMed?.map((item) => (
                                <Chip key={item._id} label={item.title} variant="outlined" sx={{ mr: 2 }} />
                            ))}
                        </Box>
                        <Box sx={{ mt: 4, display: 'flex', flexDirection: 'row' }}>
                            <CalendarIcon />
                            <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                {' '}
                                <Moment format="DD">{event?.date}</Moment> {month}{' '}
                                <Moment format="YYYY">{event?.date}</Moment>
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                            <TimeIcon />
                            <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                <Moment format="HH:mm">{event.startTime}</Moment>-
                                <Moment format="HH:mm">{event.endTime}</Moment>
                            </Typography>
                        </Box>
                        {event?.place && (
                            <Box sx={{ mt: 2, display: 'flex', flexDirection: 'row' }}>
                                <AdressIcon />
                                <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                    {event.place}
                                </Typography>
                            </Box>
                        )}

                        <Box sx={{ mt: 3 }}>
                            {event?.techRequirements?.length > 0 && (
                                <CustomGreenButton sx={{ mr: 2, mt: 1 }} onClick={techScroll}>
                                    Технические требования для просмотра
                                </CustomGreenButton>
                            )}
                            {event?.certificate && (
                                <CustomGreenButton sx={{ mr: 2, mt: 1 }} onClick={certScroll}>
                                    Получить сертификат
                                </CustomGreenButton>
                            )}
                        </Box>
                    </CustomColumn>
                </Container>
                <AboutEvent text={event?.description} title="О мероприятии" />
                <CustomColumn>
                    <Container>
                        {event?.linkToEvent?.chanelName === 'youTube' && (
                            <YouTubeVideo url={event.linkToEvent.linkToChanel} />
                        )}

                        {event?.linkToEvent?.chanelName === 'webinar' && (
                            <WebinarRuContent url={event.linkToEvent.linkToChanel} />
                        )}
                        {event?.linkToEvent?.chanelName === 'facecast' && (
                            <Forecast url={event.linkToEvent.linkToChanel} />
                        )}

                        {event?.program?.length > 0 && <ProgramTable title="Программа" data={event.program} />}
                        {event?.lectures?.length > 0 && <LectorsSlider lectures={event.lectures} />}
                        {event?.partners?.length > 0 && <Partners partners={event.partners} />}
                        {event?.linkToEvent && event.linkToEvent.linkToEvent && (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    mb: 8,
                                    mt: 8,
                                }}
                            >
                                <Link to={event?.linkToEvent?.linkToEvent}>
                                    <Atag target="_blank">
                                        <CustomButtonBlue variant="contained">
                                            <Typography
                                                // variant="h6"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: { xs: '12px', md: '22px' },
                                                }}
                                            >
                                                Зарегистрироваться
                                            </Typography>
                                        </CustomButtonBlue>
                                    </Atag>
                                </Link>
                            </Box>
                        )}
                        {event?.techRequirements?.length > 0 && (
                            <Box ref={techRef} sx={{ my: 10 }}>
                                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                                    Технические требования для просмотра
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    dangerouslySetInnerHTML={{ __html: event.techRequirements[0].requirements }}
                                    sx={{
                                        p: 1,
                                    }}
                                ></Typography>
                            </Box>
                        )}

                        {event?.certificate && (
                            <Box ref={certhRef} sx={{ my: 8 }}>
                                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                                    Для получения сертификата мероприятия необходимо
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    dangerouslySetInnerHTML={{ __html: event.certificateText }}
                                    sx={{
                                        p: 1,
                                    }}
                                ></Typography>
                            </Box>
                        )}
                    </Container>
                    <SubscribeNews />
                    <Container>
                        <Box sx={{ mt: 10 }}>
                            <MainFaq faq={faq} />
                        </Box>
                    </Container>
                </CustomColumn>
            </MainLayout>
        </>
    );
};

export default EventsInnerPage;
