/* eslint-disable */
import React from 'react';
import { Box } from '@mui/material';

const CalendarIcon = () => {
    return (
        <Box>
            <svg width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="1" y="3" width="24" height="21" rx="4" stroke="#C4C4C4" stroke-width="2" />
                <path d="M1 9H25" stroke="#C4C4C4" stroke-width="2" />
                <path d="M6 14H8" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M8 6V1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M19 6V1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M12 14H14" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M18 14H20" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M6 19H8" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M12 19H14" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M18 19H20" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
            </svg>
        </Box>
    );
};

export default CalendarIcon;
