/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@mui/material';
import MainLayout from '../MainLayout';
import PropTypes from 'prop-types';
import { getTenPastEvents, getTenFutureEvents } from '../../services/Events.service';
import MainFaq from '../../components/FAQMain';
import { getAllFaqMain } from '../../services/FAQMain.service';
import Slider from '../../components/Slider';
import {getMainSliders} from '../../services/MainSlider.service';
import EventCard from '../../components/EventCard';
import theme from '../../theme';
import EventCardSlider from '../../components/EventCardSlider';
import SubscribeNews from '../../components/SubscribeNews';
import FullPageLoader from '../../components/FullPageLoader';


function Item(props) {
    const { sx, event } = props;
    return (
        <Box
            sx={{
                color: 'white',
                p: 1,
                m: 1,
                borderRadius: 1,
                textAlign: 'center',
                fontSize: 19,
                fontWeight: '700',
                ...sx,
            }}
        >
            <EventCard event={event} key={event._id} />
        </Box>
    );
}

Item.propTypes = {
    sx: PropTypes.object,
    event: PropTypes.object,
};

export default function HomePage() {
    const [mainSliders, setMainSliders] = useState([])
    const [tenPastEvents, setTenPastEvents] = useState([])
    const [tenFutureEvents, setTenFutureEvents] = useState([])
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)


    const fetchData = async () => {
        try {
            setLoader(true)

            const sliders = await getMainSliders()
            setMainSliders(sliders)

            const pastEvents = await getTenPastEvents();
            setTenPastEvents(pastEvents)

            const futureEvents = await getTenFutureEvents();
            setTenFutureEvents(futureEvents)

            const respFaq = await getAllFaqMain();
            setFaq(respFaq)

        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        fetchData()
    }, []);

    // if (loader) {
    //     return <FullPageLoader />;
    // }


    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}
                <Box sx={{mt:8}}>
                    <Slider mainSliders={mainSliders}/>
                </Box>
                
                {tenFutureEvents.length > 0 && (
                    <Box
                        sx={{
                            background: '#F0F0F0',
                            pt: 6,
                            pb: 6,
                            mt: -2
                        }}
                    >
                        <Container>
                            <Typography variant="h4" component="h1" gutterBottom align="left">
                                Ближайшие мероприятия
                            </Typography>

                            <EventCardSlider events={tenFutureEvents} past={false} />
                        </Container>
                    </Box>
                )}
                <SubscribeNews />
                {tenPastEvents.length > 0 && (
                    <Box
                        sx={{
                            background: theme.palette.background.paper,
                            pt: 7,
                            pb: 7,
                        }}
                    >
                        <Container>
                            <Typography variant="h4" component="h1" gutterBottom align="left">
                                Прошедшие мероприятия
                            </Typography>
                            <EventCardSlider events={tenPastEvents} past={true} />
                        </Container>
                    </Box>
                )}
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
}
