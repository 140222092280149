/* eslint-disable */
import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Avatar, CardActionArea } from '@mui/material';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { getMonth } from '../../utils/getMonth';
import theme from '../../theme';
import { Link } from 'react-router-dom';

// const A = styled('a')({
//     textDecoration: 'none',
// });

const NextLink = styled(Link)({
    display: 'flex',
    alignItems: 'flex-start',
    height: '100%'
});

const ArticleCard = ({ url, article, past, index }) => {
    const monthVal = moment(article.date, 'YYYY/MM/DD').format('M');
    // const month = getMonth(monthVal);

    const directionMed = article.directionMed.map((type) => (
        <Chip
            variant="outlined"
            label={type.title}
            key={type._id}
            sx={{
                mt: 1,
                mr: 1,
                mb: 0,
            }}
        />
    ));

    const addComma = (index) => {
        if (article.lectures && article.lectures.length - 1 !== index) {
            return ',';
        }
    };

    const backColor = () => {
        let indx = index + 1;

        if (url === 'specproject') {
            if (indx % 2 === 0) {
                return 'linear-gradient(166.4deg, #FFFFFF 39.65%, #A5FFC4 111.99%)';
            } else {
                return 'linear-gradient(166.4deg, #FFFFFF 39.65%, #A5FFFF 111.99%)';
            }
        }

        return theme.palette.primary.light;
    };

    return (
        <NextLink to={`../${url}/${article._id}`}>
            {/* <A> */}
                <Card
                    sx={{
                        // maxWidth: '300px',
                        height: '100%',
                        minHeight: '250px',
                        background: backColor,
                        boxShadow: '5px 5px 15px rgba(159, 112, 149, 0.20)',
                        border: '1px solid #f7f2f2',
                    }}
                >
                    <CardActionArea
                        sx={{
                            height: '100%',

                            display: 'flex',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'flex-start',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignSelf: 'flex-start',
                                    width: '100%',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',

                                        justifyContent: 'space-around',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                        }}
                                    >
                                        <Box>{directionMed}</Box>
                                        <Box>{past}</Box>
                                    </Box>

                                    {article?.directionMed?.length > 0 && (
                                        <Box
                                            sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}
                                            key={article.directionMed._id}
                                        >
                                            {article?.directionMed?.map((item) => (
                                                <Box sx={{ ml: 1 }} key={item._id}>
                                                    <img src={item.imageUrl} width="35px" height="35px" />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignSelf: 'flex-start',
                                    width: '100%',
                                    height: '100% ',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    variant="subtitle1"
                                    component="p"
                                    mt={3}
                                    fontWeight={500}
                                    mb={3}
                                >
                                    {article?.title}
                                </Typography>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        marginLeft: '10px',
                                    }}
                                >
                                    {article?.lectures &&
                                        article?.lectures?.map((lecture) => {
                                            return (
                                                <Box
                                                    sx={{
                                                        marginLeft: '-10px',
                                                    }}
                                                    key={lecture._id}
                                                >
                                                    <Avatar alt={lecture.fio} src={lecture.photoUrl} loading="eager" />
                                                </Box>
                                            );
                                        })}
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                    }}
                                >
                                    {article?.lectures &&
                                        article?.lectures?.map((lecture, index) => (
                                            <Typography
                                                variant="body2"
                                                mt={2}
                                                mr={0.5}
                                                sx={{ lineHeight: '1' }}
                                                key={lecture._id}
                                            >
                                                {lecture.fio}
                                                {addComma(index)}
                                            </Typography>
                                        ))}
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            {/* </A> */}
        </NextLink>
    );
};

export default ArticleCard;
