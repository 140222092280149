/* eslint-disable */
import React, { useCallback, useEffect, useState } from 'react';
// import { useEmblaCarousel } from 'embla-carousel/react';
import useEmblaCarousel from 'embla-carousel-react'
import { Box, Container, TableRow, Typography } from '@mui/material';
import { NextButton, PrevButton } from './EmblaCarouselButtons';
import { styled } from '@mui/material/styles';
import ModalComponent from '../../components/Modal';


const ImageLector = styled('img')(({ theme }) => ({
    borderRadius: '50%',
}));

const A = styled('a')(({ theme }) => ({
    textDecoration: 'none',
    fontWeight: 'bold',
    color: theme.palette.primary.dark,
    cursor: 'pointer',
}));

const LectorsSlider = ({ lectures }) => {
    const [viewportRef, embla] = useEmblaCarousel({
        dragFree: true,
        inViewThreshold: 0,
        containScroll: 'trimSnaps',
        selectedClass: '',
        draggingClass: '',
        draggableClass: '',
        slidesToScroll: 2,
    });

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const [speaker, setSpeaker] = useState({});
    const [openModal, setOpenModal] = React.useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    useEffect(() => {
        if (!embla) return;
        embla.on('select', onSelect);
        onSelect();
    }, [embla, onSelect]);

    const handleOpen = () => {
        setOpenModal((prev) => !prev);
    };

    const handleChangeSpeaker = (speaker) => {
        setSpeaker(speaker);
        setOpenModal((prev) => !prev);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                padding: '10px 0px',
                mt: 8,
                mb: 4
            }}
        >
            <ModalComponent data={speaker} handleOpen={handleOpen} openModal={openModal} />

            <Typography variant="h4" sx={{ fontWeight: 700, mb: 5 }}>
                Спикеры
            </Typography>
            <Box
                ref={viewportRef}
                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    padding: '5px',
                    '.is-draggable': {
                        cursor: 'move',
                    },
                    '.is-dragging': {
                        cursor: 'grabbing',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        userSelect: 'none',
                        marginLeft: '-10px',
                    }}
                >
                    {lectures.map((lector) => (
                            <Box
                                key={lector._id}
                                sx={{
                                    minWidth: { xs: '50%', md: '20%' },
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    <A onClick={() => handleChangeSpeaker(lector)}>

                                        <ImageLector src={lector.photoUrl} width="120px" height="120px" loading="eager" />

                                        <Typography variant="subtitle1" sx={{ mt: 2, textAlign: 'center', maxWidth: '150px' }}>
                                            {lector.fio}
                                        </Typography>
                                    </A>
                                </Box>
                            </Box>
                      
                    ))}
                </Box>
            </Box>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </Box>
    );
};

export default LectorsSlider;
