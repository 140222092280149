/* eslint-disable */

import { Store } from "../contexts/Store";
import * as React from 'react';
import { loginSpecProject } from "../services/AuthServiceSpecProject";
import axios from "axios";

export const useAuthSpecProjects = () => {
    const { state, dispatch } = React.useContext(Store);

    const login = async (userData) => {
        const response = await loginSpecProject(userData)
        localStorage.setItem("token", response.accessToken)
        localStorage.setItem("user", JSON.stringify({...response.user, code: userData.code}))

        dispatch({ type: 'USER_LOGIN', payload: {...response.user, code: userData.code} });
        return response
    };

    const logout = () => {
        localStorage.removeItem("token")
        localStorage.removeItem('user')

        dispatch({ type: 'USER_LOGOUT' });
        
    };

    const checkAuth = async () => {
        try {
            dispatch({ type: 'START_USER_LOADING' });

            const response = await axios.get(`http://localhost:5001/api/auth-spec-project/check-token`, {
                withCredentials: true,
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
            });  
            return response;
        } catch (error) {
            console.log(error.response);
            if(error.status !== 200) {
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                dispatch({ type: 'USER_LOGOUT' });
            }
        } finally {
            dispatch({ type: 'STOP_USER_LOADING' });
        }
    }

    return {
        login,
        logout, 
        checkAuth
    };
};
