/* eslint-disable */
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import theme from '../../theme';
import RecommendationCard from '../RecommendationCard';

const RecommendationILibraryInner = ({ data }) => {
    return (
        <Box
            sx={{
                pb: 3,
                py: 8,
                maxWidth: { xs: '100%', md: '300px' },
                minWidth: { xs: '100%', md: '300px' },
                width: '100%',
            }}
        >
            <Container>
                <Box
                    sx={{
                        background: theme.palette.primary.main,
                        p: 2,
                        color: theme.palette.background.default,
                        mb: 2,
                    }}
                >
                    <Typography variant="h5">Мы рекомендуем</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    {data.map((article) => (
                        <Box
                            key={article._id}
                            sx={{
                                position: 'relative',
                                minWidth: '100%',
                                width: '100%',
                                pb: 2,
                            }}
                        >
                            <RecommendationCard item={article.item} onModel={article.onModel} width="100%" />
                        </Box>
                    ))}
                </Box>
            </Container>
        </Box>
    );
};

export default RecommendationILibraryInner;
