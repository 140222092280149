/* eslint-disable react/jsx-filename-extension */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Container, Box } from '@mui/material';
// import router, { useRouter } from 'next/router';
import MainLayout from '../MainLayout';
import InnerBanner from '../../components/InnerBanner';
import { getAllRecommendation } from '../../services/Recommendation.service';
import { getAllBanners } from '../../services/Banner.service';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import videoCategory from '../../assets/images/videoCategory.png';
import publicationCategory from '../../assets/images/publicationCategory.png';
import pharmNewsCategory from '../../assets/images/pharmNewsCategory.png';
import forPharmacyCategory from '../../assets/images/forPharmacyCategory.png';
import CategoryCard from '../../components/CategoryCard';
import { getAllFaqMain } from '../../services/FAQMain.service';
import RecommendationLibrary from '../../components/RecommendationLibrary';
import FullPageLoader from '../../components/FullPageLoader';

const category = [
    { id: 1, url: '/videos', img: videoCategory, alt: 'Видео' },
    { id: 2, url: '/publications', img: publicationCategory, alt: 'Публикации' },
    { id: 3, url: '/pharmnews', img: pharmNewsCategory, alt: 'Фармновости' },
    { id: 4, url: '/for-pharmacies', img: forPharmacyCategory, alt: 'Для аптек' },
];

export default function LibraryPage() {
    const [recommendation, setRecommendation] = React.useState();
    const [banner, setBanner] = React.useState();
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)
  
    const fetchData = async () => {
      try {
        setLoader(true)
        const recommendationData = await getAllRecommendation()
        setRecommendation(recommendationData.filter((el) => el.item !== null))

        const banner = await getAllBanners();
        setBanner(banner);

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

      } catch (error) {
          console.log(error);
      } finally {
        setLoader(false)
      }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}

                <InnerBanner banner={banner} />
                <Box>
                    <Container>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                                justifyContent: 'space-between',
                                my: 8,
                            }}
                        >
                            {category.map((card) => (
                                <Box key={card.id} sx={{ my: { xs: 2, md: 0 }, mx: 1 }}>
                                    <CategoryCard url={card.url} img={card.img} alt={card.alt} />
                                </Box>
                            ))}
                        </Box>
                    </Container>
                </Box>
                {recommendation?.length > 0 && <RecommendationLibrary data={recommendation} />}

                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
}
