/* eslint-disable react/jsx-filename-extension */
/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
    Container,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Chip,
    ListItemText,
    ListItemIcon,
    Checkbox,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
// import router, { useRouter } from 'next/router';
import MainLayout from '../MainLayout';
import InnerBanner from '../../components/InnerBanner';
import Pagination from '../../components/Pagintion';
import { getAllPublications } from '../../services/Publications.service';
import { getAllDirectionMed } from '../../services/DirectionMed.service';
import filterSearch from '../../utils/filterSearch';
import { getAllBanners } from '../../services/Banner.service';
import SubscribeNews from '../../components/SubscribeNews';
import { getAllFaqMain } from '../../services/FAQMain.service';
import MainFaq from '../../components/FAQMain';
import LibraryCard from '../../components/LibraryCard';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'menu',
};

function getStyles(name, selected, theme) {
    return {
        fontWeight:
            selected.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

// eslint-disable-next-line react/prop-types
export default function PublicationsPage() {
    const theme = useTheme();
    const [page, setPage] = React.useState(1);
    const [selected, setSelected] = useState([]);

    const [publications, setPublication] = React.useState();

    const [directionMed, setDirectionMed] = React.useState();
    const [medCategory, setMedCategory] = React.useState('all');
    const [banner, setBanner] = React.useState();
    const [faq, setFaq] = useState([])

    // const router = useRouter();
    // const { query } = useRouter();

    useEffect(() => {
        if (medCategory === 'all') {
            setSelected([]);
        } else {
            setSelected(medCategory);
        }
    }, [medCategory]);

    // const {
    //     query: { term, impact, location },
    // } = useRouter();

    // const initialValue = {
    //     make: query.make || 'all',
    // };

    const isAllSelected = directionMed?.length > 0 && selected?.length === directionMed?.length;

    const handleChange = (event) => {
        const { value } = event.target;
        if (value[value.length - 1] === 'all') {
            setSelected(
                selected.length === directionMed.length ? [] : Object.values(directionMed).map((item) => item.title),
            );
            return;
        }
        setSelected(value);
        filterSearch(router, value);
    };

    const fetchPublication = async (category) => {
        const data = await getAllPublications(page, category);
        setPublication(data);
    }

    useEffect(() => {
        const category = selected || 'all';
        fetchPublication(category)
    }, [selected, page]);

    const fetchData = async () => {
      try {
          const direction = await getAllDirectionMed();
          setDirectionMed(direction)

          const banner = await getAllBanners();
          setBanner(banner);

          const respFaq = await getAllFaqMain();
          setFaq(respFaq)

      } catch (error) {
          console.log(error);
      }
    }

    useEffect(() => {
      fetchData()
    }, [])


    return (
        <>
            <MainLayout>
                <InnerBanner banner={banner} />
                <Box sx={{ background: '#F0F0F0' }}>
                    <Container>
                        <Box pt={8} pb={2}>
                            <Typography variant="h4" component="h1" gutterBottom align="left">
                                Публикации
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mr: 10,
                                }}
                            >
                                <Typography variant="subtitle1">Медицинская область</Typography>

                                <FormControl sx={{ mt: 2, mb: 5, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Область медицины</InputLabel>
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        value={selected}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        label="Область медицины"
                                        sx={{ borderRadius: '30px' }}
                                    >
                                        <MenuItem value="all">
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={isAllSelected}
                                                    indeterminate={
                                                        selected.length > 0 && selected.length < directionMed.length
                                                    }
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Выбрать все" />
                                        </MenuItem>
                                        {directionMed?.map((name) => (
                                            <MenuItem key={name.title} value={name.title}>
                                                <ListItemIcon>
                                                    <Checkbox checked={selected.indexOf(name.title) > -1} />
                                                </ListItemIcon>
                                                <ListItemText primary={name.title} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'column',
                            }}
                        >
                            {publications?.publications.map((item) => (
                                <Box key={item._id} sx={{ my: 1 }}>
                                    <LibraryCard data={item} link="publications" />
                                </Box>
                            ))}
                        </Box>

                        <Pagination url="publications" pages={publications?.pages} setPage={setPage} />
                    </Container>
                </Box>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
}
