/* eslint-disable */
import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Avatar, Box } from '@mui/material';

export default function ModalComponent({ data, handleOpen, openModal }) {
    return (
        <Dialog
            open={openModal}
            onClose={handleOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <Box sx={{ py: 6, px: 10, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Box>
                    <Avatar alt={data.fio} src={data.photoUrl} sx={{ width: 300, height: 300 }} />
                </Box>
                <DialogTitle id="alert-dialog-title">{data.fio}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
                        {data.progress}
                    </DialogContentText>
                </DialogContent>
            </Box>
            <DialogActions>
                <Button variant="outlined" onClick={handleOpen}>
                    Закрыть
                </Button>
            </DialogActions>
        </Dialog>
    );
}
