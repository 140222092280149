/* eslint-disable */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import theme from '../../theme';
import { Link } from 'react-router-dom';

const A = styled('a')({
    textDecoration: 'none',
});

const NextLink = styled(Link)({
    display: 'flex',
    alignItems: 'flex-start',
});

const RecommendationCard = ({ item, onModel, width }) => {
    // eslint-disable-next-line consistent-return
    const getLink = (model) => {
        if (model === 'Video') {
            return 'videos';
        } else if (model === 'Publications') {
            return 'publications';
        } else if (model === 'PharmNews') {
            return 'pharmnews';
        } else if (model === 'ForPharmacies') {
            return 'for-pharmacies';
        }
    };
    return (
        <NextLink to={`../${getLink(onModel)}/${item._id}`}>
            <A>
                <Card
                    sx={{
                        maxWidth: width,
                        height: '100%',
                    }}
                >
                    <CardActionArea
                        sx={{
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            alignSelf: 'flex-start',
                            background: theme.palette.background.default,
                        }}
                    >
                        <CardMedia component="img" image={item.imgUrl} alt={item.title} />
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'flex-start',
                                // width: '100%',
                                height: '100%',
                            }}
                        >
                            <Typography variant="body2">{item.title}</Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>
            </A>
        </NextLink>
    );
};

export default RecommendationCard;
