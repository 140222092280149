/* eslint-disable */
import React from 'react';
import { Box } from '@mui/material';

const MailIcon = () => {
    return (
        <Box>
            <svg width="28" height="19" viewBox="0 0 28 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24.6472 0H2.375C1.06279 0 0 1.06938 0 2.375V16.625C0 17.9384 1.0706 19 2.375 19H24.6472C25.9485 19 27.0222 17.9429 27.0222 16.625V2.375C27.0222 1.07171 25.9637 0 24.6472 0ZM24.3146 1.58333C23.8294 2.06599 15.4788 10.3726 15.1905 10.6594C14.7419 11.108 14.1455 11.355 13.5111 11.355C12.8767 11.355 12.2803 11.1079 11.8302 10.6579C11.6363 10.465 3.37794 2.25013 2.70761 1.58333H24.3146ZM1.58333 16.3027V2.69826L8.42534 9.50422L1.58333 16.3027ZM2.70861 17.4167L9.54792 10.6208L10.7121 11.7789C11.4598 12.5266 12.4538 12.9383 13.5111 12.9383C14.5684 12.9383 15.5624 12.5266 16.3086 11.7804L17.4743 10.6208L24.3136 17.4167H2.70861ZM25.4389 16.3027L18.5969 9.50422L25.4389 2.69826V16.3027Z"
                    fill="#C4C4C4"
                />
            </svg>
        </Box>
    );
};

export default MailIcon;
