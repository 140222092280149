/* eslint-disable */
import React from 'react';

const YoutubeSvg = ({ fill }) => (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.9763 27.9525C21.6951 27.9525 27.9525 21.6951 27.9525 13.9763C27.9525 6.25739 21.6951 0 13.9763 0C6.25739 0 0 6.25739 0 13.9763C0 21.6951 6.25739 27.9525 13.9763 27.9525ZM20.1434 8.76198C20.8018 8.94168 21.3209 9.46778 21.498 10.1352C21.8273 11.3541 21.8146 13.8949 21.8146 13.8949C21.8146 13.8949 21.8146 16.4228 21.4982 17.6419C21.3209 18.3092 20.8019 18.8352 20.1434 19.0149C18.9407 19.3358 14.13 19.3358 14.13 19.3358C14.13 19.3358 9.3317 19.3358 8.11642 19.0021C7.45799 18.8224 6.93904 18.2963 6.76175 17.629C6.44524 16.4228 6.44524 13.8821 6.44524 13.8821C6.44524 13.8821 6.44524 11.3541 6.76175 10.1352C6.93885 9.46787 7.47065 8.92885 8.11633 8.74933C9.31904 8.42843 14.1298 8.42843 14.1298 8.42843C14.1298 8.42843 18.9407 8.42843 20.1434 8.76198ZM16.5986 13.8821L12.5981 11.5467V16.2175L16.5986 13.8821Z"
            fill={fill}
            fillOpacity="0.7"
        />
    </svg>
);

export default YoutubeSvg;
