/* eslint-disable */
import React from 'react';
import { Box, Button, Chip, Container, Typography } from '@mui/material';
// import BannerInner from '../../public/img/InnerBanner.png';
import theme from '../../theme';
import Moment from 'react-moment';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { getMonth } from '../../utils/getMonth';
// import Image from 'next/image';
import WhiteLogo from '../../assets/images/whiteLogo.png';
import { Link } from 'react-router-dom';

const initTextBanner = 'Профессиональная \n конференц-площадка \n для врачей и провизоров';

const CustomButton = styled(Button)(({ theme }) => ({
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.light,
    borderRadius: 30,
    padding: '10px 15px',
    '&:hover': {
        backgroundColor: theme.palette.secondary.light,
    },
}));

const CustomChip = styled(Chip)({
    fontWeight: 900,
});

const Atag = styled('a')({
    textDecoration: 'none',
});

const InnerBanner = ({ children, banner }) => {
    let monthVal;
    let month;
    if (banner?.banner[0]?.item?.date) {
        monthVal = moment(banner?.banner[0]?.item?.date, 'YYYY/MM/DD').format('M');
        month = getMonth(monthVal);
    }

    const typeBanner = () => {
        if (children) {
            return (
                <Box
                    sx={{
                        background: 'linear-gradient(91.2deg, #A4CE52 5.64%, #10B8B4 73.27%)',
                        marginTop: { xs: '55px', md: '75px' },
                        display: 'flex',
                        alignItems: 'center',
                        py: { xs: 5, md: 8 },
                    }}
                >
                    <Container
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        {children}
                    </Container>
            </Box>
        )}

        if(banner?.bannerImage.length > 0) {
            return (
                <Box
                    sx={{
                        width: '100%',
                        cursor: 'pointer',
                    }}
                >        
                    <Link to={banner?.bannerImageLink}>

                        <img src={banner?.bannerImage} alt="" width="100%" />
                    </Link>
                </Box>
            )
        }

        if(banner?.banner[0]?.item) {
            const bannerData = banner?.banner[0]?.item;
            return (
                <Box
                    sx={{
                        background: 'linear-gradient(91.2deg, #A4CE52 5.64%, #10B8B4 73.27%)',
                        marginTop: { xs: '55px', md: '75px' },
                        display: 'flex',
                        alignItems: 'center',
                        py: { xs: 5, md: 8 },
                    }}
                >
                    <Container
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: { xs: '100%', md: '70%' },
                                minWidth: '70%',
                            }}
                        >
                            <Box>
                                {bannerData &&
                                    bannerData.typeEvent &&
                                    bannerData.typeEvent.map((type) => (
                                        <CustomChip
                                            label={type.title}
                                            variant="outlined"
                                            sx={{
                                                mr: 2,
                                                mb: 2,
                                                color: theme.palette.primary.light,
                                                borderColor: theme.palette.primary.light,
                                            }}
                                        />
                                    ))}
                            </Box>
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '24px', md: '40px' },
                                    color: theme.palette.primary.light,
                                }}
                            >
                                {bannerData?.title}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '100%', md: '70%' },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: { xs: 'flex-start', md: 'center' },
                                alignSelf: 'center',
                            }}
                        >
                            <Typography
                                variant="h4"
                                sx={{
                                    color: theme.palette.primary.light,
                                    fontWeight: 700,
                                    mb: 5,
                                    marginTop: { xs: '10px', md: '0px' },
                                    fontSize: { xs: '20px', md: '32px' },
                                }}
                            >
                                {bannerData && bannerData.date && (
                                    <>
                                        <Moment format="DD">{bannerData.date}</Moment> {month}{' '}
                                        <Moment format="YYYY">{bannerData.date}</Moment>
                                    </>
                                )}
                            </Typography>
        
                            {bannerData.linkToEvent && bannerData.linkToEvent && (
                                <Link to={bannerData?.linkToEvent}>
                                    <Atag target="_blank">
                                        <CustomButton variant="contained">
                                            <Typography
                                                // variant="h6"
                                                sx={{
                                                    fontWeight: 700,
                                                    fontSize: { xs: '12px', md: '22px' },
                                                }}
                                            >
                                                Зарегистрироваться
                                            </Typography>
                                        </CustomButton>
                                    </Atag>
                                </Link>
                            )}
                        </Box>
                    </Box>
                    </Container>
                </Box>
            )
        }

        return (
            <Box
                sx={{
                    background: 'linear-gradient(91.2deg, #A4CE52 5.64%, #10B8B4 73.27%)',
                    marginTop: { xs: '55px', md: '75px' },
                    display: 'flex',
                    alignItems: 'center',
                    py: { xs: 5, md: 8 },
                }}
            >
                <Container
                    sx={{
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            w="100%"
                            maxWidth="100%"
                            height="auto"
                            sx={{ marginTop: { xs: '0px', md: '10px' }, mb: 3 }}
                        >
                            <Box position="relative" height="auto" sx={{ width: { xs: '280px', md: '400px' } }}>
                                <img
                                    src={WhiteLogo}
                                    layout="responsive"
                                    alt="Congress.by"
                                    width="100%"
                                    // objectFit="contain"
                                />
                            </Box>
                        </Box>
                        <Typography
                            variant="h5"
                            sx={{
                                width: '300px',
                                textAlign: 'center',
                                color: theme.palette.primary.light,
                                whiteSpace: 'pre-line',
                            }}
                        >
                            {initTextBanner}
                        </Typography>
                    </Box>
                </Container>
            </Box>
        )
    }

    return (
        <>
            { typeBanner()}  
        </>
    );
};

export default InnerBanner;
