/* eslint-disable */
import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from "react-router-dom";
import theme from '../../theme';
import Box from '@mui/material/Box';

const headersData = [
    {
        label: 'Главная',
        href: '/',
    },
    {
        label: 'Мероприятия',
        href: '/events',
    },
    {
        label: 'Библиотека',
        href: '/library',
    },
    {
        label: 'Спецпроекты',
        href: '/specprojects',
    },
    {
        label: 'Спикеры',
        href: '/speakers',
    },
    {
        label: 'Контакты',
        href: '/contacts',
    },
];

const MenuLink = styled(Link, {
    shouldForwardProp: (prop) => prop !== 'mobileView',
})(({ mobileView }) => ({
    padding: '13px 18px',
    textDecoration: 'none',
    fontWeight: 'bold',
    cursor: 'pointer',
    color: theme.palette.primary.dark,

    ...(mobileView && {
        display: 'flex',
        flexDirection: 'row',
        minWidth: '250px',
        color: theme.palette.primary.dark,
        paddingLeft: '20px',
    }),
}));

const Menu = ({ mobileView }) => {
    return (
        <Box sx={{ mt: { xs: 3, md: 1.3 } }}>
            {headersData.map(({ label, href }) => (
                <MenuLink
                    sx={{
                        width: (mobileView) => (mobileView ? '100%' : ''),
                    }}
                    {...{
                        key: label,
                        color: 'inherit',
                        to:href,
                    }}
                    mobileView={mobileView}
                >
                    {label}
                </MenuLink>
            ))}
        </Box>
    );
};

export default Menu;
