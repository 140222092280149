/* eslint-disable */
import React from 'react';
import { Card, CardActionArea, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const A = styled('a')({
    textDecoration: 'none',
});

const CategoryCard = ({ url, img, alt }) => {
    return (
        <Link to={url}>
            {/* <A> */}
                <Card>
                    <CardActionArea>
                        <CardMedia component="img" image={img} alt={alt} />
                    </CardActionArea>
                </Card>
            {/* </A> */}
        </Link>
    );
};

export default CategoryCard;
