/* eslint-disable */

import React from 'react';
import { Box, Typography } from '@mui/material';

const WebinarRuContent = ({ url }) => {
    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: 500, mb: 5, textAlign: 'center' }}>
                ИНФОРМАЦИЯ В ДАННОМ ВИДЕО ПРЕДНАЗНАЧЕНА ТОЛЬКО ДЛЯ СПЕЦИАЛИСТОВ ЗДРАВООХРАНЕНИЯ
            </Typography>
            <Box
                sx={{ position: 'relative', width: '100%', paddingBottom: '56.25%', height: 0, mb: 8 }}
                dangerouslySetInnerHTML={{
                    __html: ` <iframe src=${url} style="position: absolute; top:0; left: 0; width: 100%; height: 100%; border: none"  allowFullScreen> </iframe>`,
                }}
            />
        </>
    );
};

export default WebinarRuContent;
