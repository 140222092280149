import React from 'react';
import { Fade } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import { styled } from '@mui/material/styles';

const Img = styled('img')({
    width: '100%',
});

const Slider = ({ mainSliders }) => {
    const settings = {
        indicators: true,
        // scale: 5,
        arrows: true,
        autoplay: true,
        prevArrow: (
            <div style={{ width: '40px', marginRight: '-40px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
                    <path
                        d="M116.4,248.1l225.1,225.1c5.3,5.3,13.8,5.3,19.1,0c5.3-5.3,5.3-13.8,0-19.1L145.1,238.6L360.6,23.1
		c5.3-5.3,5.3-13.8,0-19.1c-2.6-2.6-6.1-4-9.5-4s-6.9,1.3-9.5,4L116.5,229.1C111.2,234.3,111.2,242.9,116.4,248.1z"
                    />
                </svg>
            </div>
        ),
        nextArrow: (
            <div style={{ width: '40px', marginLeft: '-40px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="#fff">
                    <path
                        d="M360.7,229.1L135.6,4c-5.3-5.3-13.8-5.3-19.1,0c-5.3,5.3-5.3,13.8,0,19.1L332,238.6L116.5,454.1c-5.3,5.3-5.3,13.8,0,19.1
		c2.6,2.6,6.1,4,9.5,4s6.9-1.3,9.5-4l225.1-225.1C365.9,242.9,365.9,234.3,360.7,229.1z"
                    />
                </svg>
            </div>
        ),
    };

    return (
        <div className="slide-container">
            <Fade {...settings}>
                {mainSliders &&
                    mainSliders.map((slide, index) => (
                        <div className="each-fade" key={index}>
                            <div className="image-container">
                                <a href={slide.linkTo} target="_blank" rel="noreferrer">
                                    <Img src={slide.imageLink} alt="" />
                                </a>
                            </div>
                        </div>
                    ))}
            </Fade>
        </div>
    );
};

export default Slider;
