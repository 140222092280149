/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable react/jsx-filename-extension */
// import Cookies from 'js-cookie';
import { createContext, useReducer } from 'react';

let locStorage;
if (typeof window !== 'undefined') {
    // Perform localStorage action
    locStorage = JSON.parse(localStorage.getItem('user'));
}

export const Store = createContext();
const initialState = {
    user: locStorage || null,
    isAuth: !!locStorage || null,
    isLoading: false,
};

function reducer(state, action) {
    switch (action.type) {
        case 'USER_LOGIN':
            return { ...state, user: action.payload, isAuth: true };
        case 'USER_LOGOUT':
            return {
                ...state,
                user: null,
                isAuth: false,
            };
        case 'START_USER_LOADING':
            return {
                ...state,
                isLoading: true,
            };
        case 'STOP_USER_LOADING':
            return {
                ...state,
                isLoading: false,
            };
        default:
            return state;
    }
}

export function StoreProvider({ children }) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = { state, dispatch };
    return <Store.Provider value={value}>{children}</Store.Provider>;
}
