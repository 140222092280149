/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/prefer-default-export */
import client from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAdSpecprojects = async (code) => {
    const response = await client.post('/ad-spec-project/material/by-code', code);
    return response.data;
};

export const getAdSpecproject = async (id) => {
    const response = await client.get(`/ad-spec-project/material/${id}`);
    return response.data;
};
