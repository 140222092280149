import { get } from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAllSpecProjects = async (page, category) => {
    const params = {
        params: {
            type: 'all',
            page,
            category,
        },
    };
    const response = await get(`spec-projects`, params);
    return response.data.allSpecProjects;
};

export const getSpecProjects = async (id) => {
    const response = await get(`spec-projects/${id}`);
    return response.data;
};
