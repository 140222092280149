/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { Box } from '@mui/material';
// import { useEmblaCarousel } from 'embla-carousel/react';
import useEmblaCarousel from 'embla-carousel-react'

import EventCard from '../EventCard/index';
import { PrevButton, NextButton } from './EmblaCarouselButtons';

//used Embla Carousel
const EventCardSlider = ({ events, past }) => {
    const [viewportRef, embla] = useEmblaCarousel({
        dragFree: true,
        inViewThreshold: 0,
        containScroll: 'trimSnaps',
        selectedClass: '',
        draggingClass: '',
        draggableClass: '',
        // slidesToScroll: 2,
        slidesToScroll: 1,
    });

    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
        if (!embla) return;
        setPrevBtnEnabled(embla.canScrollPrev());
        setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    useEffect(() => {
        if (!embla) return;
        embla.on('select', onSelect);
        onSelect();
    }, [embla, onSelect]);

    return (
        <Box
            sx={{
                position: 'relative',
                padding: '10px 0px',
            }}
        >
            <Box
                ref={viewportRef}
                sx={{
                    overflow: 'hidden',
                    width: '100%',
                    padding: '15px 10px',
                    '.is-draggable': {
                        cursor: 'move',
                        // cursor: 'grab',
                    },
                    '.is-dragging': {
                        cursor: 'grabbing',
                    },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        userSelect: 'none',
                        marginLeft: '-10px',
                    }}
                >
                    {events.map((event) => (
                        <Box
                            key={event._id}
                            sx={{
                                // position: 'relative',

                                minWidth: { xs: '100%', md: '25%' },
                                maxWidth: { xs: '100%', md: '30%' },
                                // width: { xs: '100%', md: '25%' },

                                paddingLeft: '15px',
                            }}
                        >
                            <Box
                                sx={{
                                    height: '100%',
                                    minWidth: { xs: '100%', md: '25%' },
                                }}
                            >
                                <EventCard event={event} key={event._id} past={past} />
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
        </Box>
    );
};

export default EventCardSlider;
