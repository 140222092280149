/* eslint-disable */

import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {default as MuiLink} from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
// import { useRouter } from 'next/router';
import { registrationUserSpecProject } from '../../services/RegistrSpecProject.servise';
import { useSnackbar } from 'notistack';
import { registrationSpecProject } from '../../services/AuthServiceSpecProject';
import { Link, useNavigate } from 'react-router-dom';


export default function RegistrForm() {

    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const [error, setError] = React.useState(false);

    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        if(!data.get('politics')) {
            setError(true)
            return
        }

        const userData = {
            fio: data.get('fio'),
            email: data.get('email'),
            phone: data.get('phone'),
            speciality: data.get('speciality'),
            city: data.get('city'),
            isPoliticsAgree: data.get('politics'),
        }

        try{
            const resp = await registrationSpecProject(userData)
            enqueueSnackbar('Регистрация прошла успешна!', {variant: "success"});

            navigate('/login-specprojects')
            console.log('resp', resp);
        } catch (err) {
            console.log('error', err.message);
            enqueueSnackbar(
                err.response && err.response.data && err.response.data.message
                ? err.response.data.message
                : err.message,
                {variant: "error"}
            );
        } finally {
            setError(false)
        }
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    // marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Регистрация
                </Typography>
                {error && <Alert severity="error" sx={{mt: 2}}>Подтвердите что согласны с политикой конфиденциальности</Alert>}
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                autoComplete="given-name"
                                name="fio"
                                required
                                fullWidth
                                id="fio"
                                label="ФИО"
                                autoFocus
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                label="Email"
                                name="email"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="phone"
                                label="Номер телефона"
                                name="phone"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="speciality"
                                label="Специальность"
                                type="speciality"
                                id="speciality"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="city"
                                label="Город"
                                type="city"
                                id="city"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel
                                control={<Checkbox name='politics' value="yes" color="primary" />}
                                label={
                                    <>
                                        Политика конфиденциальности
                                    </>
                                }
                            />
                        </Grid>
                    </Grid>
                    <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                        Регистрация
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/login-specprojects" >
                                <MuiLink variant="body2" sx={{ cursor: "pointer" }}>
                                    Уже зарегистрированы? Войти
                                </MuiLink>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
}


