import { get } from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAllPublications = async (page, category) => {
    const params = {
        params: {
            type: 'all',
            page,
            category,
        },
    };
    const response = await get(`libraries/publications`, params);
    return response.data.allPublications;
};

export const getPublication = async (id) => {
    const response = await get(`libraries/publications/${id}`);
    return response.data;
};
