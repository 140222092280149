import { post } from '../http';

export const getToken = async (body) => {
    const response = await post(`https://api.sendpulse.com/oauth/access_token`, body);
    return response.data;
};

export const subscribeNews = async (body, token) => {
    const response = await post(`https://api.sendpulse.com/addressbooks/677535/emails`, body, token);
    return response.data;
};
