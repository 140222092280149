/* eslint-disable */
import React from 'react';
import { Box } from '@mui/material';

const timeIcon = () => {
    return (
        <Box>
            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="13" cy="13" r="12" stroke="#C4C4C4" stroke-width="2" />
                <path d="M13 7V14" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
                <path d="M16 17L13 14" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round" />
            </svg>
        </Box>
    );
};

export default timeIcon;
