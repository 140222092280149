/* eslint-disable */
import React from 'react';
import { Box } from '@mui/material';
// import Image from 'next/image';
import { styled } from '@mui/material/styles';
import YoutubeSvg from './youtubeSvg';
import FacebookSvg from './facebookSvg';
import InstagramSvg from './instagramSvg';
import TelegramSvg from './telegramSvg';

const CustomBox = styled(Box)({
    margin: '5px',
    cursor: 'pointer',
});

const SocialMediaIcons = ({ fill, footer }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: () => (footer ? { md: 'left', xs: 'center' } : { xs: 'left', md: 'center' }),
                ml: () => (footer ? { xs: 0, md: 0 } : { xs: 2, md: 3 }),
                mt: { xs: 2, md: 0.5 },
            }}
        >
            <CustomBox>
                <a href="https://www.youtube.com/channel/UCNLyQzxb0veu3w9xwUJAS5Q/featured" target="_blank">
                    <YoutubeSvg fill={fill} />
                </a>
            </CustomBox>
            <CustomBox>
                <a href="https://www.instagram.com/congress.by/" target="_blank">
                    <InstagramSvg fill={fill} />
                </a>
            </CustomBox>
        </Box>
    );
};

export default SocialMediaIcons;
