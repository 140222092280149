/* eslint-disable no-use-before-define */
/* eslint-disable */
import { AppBar, Toolbar, Typography, IconButton, Drawer, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from "react-router-dom";
// import Link from 'next/link';
// import Image from 'next/image';
import React, { useState, useEffect } from 'react';
import Menu from '../Menu';
import SocialMediaIcons from '../SocialMediaIcons';
import logo from '../../assets/images/logo_congress.png';

export default function Header() {
    const [state, setState] = useState({
        mobileView: false,
        drawerOpen: false,
    });

    const { mobileView, drawerOpen } = state;

    useEffect(() => {
        const setResponsiveness = () => {
            return window.innerWidth < 900
                ? setState((prevState) => ({ ...prevState, mobileView: true }))
                : setState((prevState) => ({ ...prevState, mobileView: false }));
        };

        setResponsiveness();

        window.addEventListener('resize', () => setResponsiveness());
    }, []);

    const displayDesktop = () => {
        return (
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Link to="/">
                    <img  width="185px" src={logo} alt="Congress.by" />
                </Link>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        py: 2,
                    }}
                >
                    <Menu mobileView={mobileView} />
                    <SocialMediaIcons fill="#12B8B3" />
                </Box>
            </Toolbar>
        );
    };

    const displayMobile = () => {
        const handleDrawerOpen = () => setState((prevState) => ({ ...prevState, drawerOpen: true }));
        const handleDrawerClose = () => setState((prevState) => ({ ...prevState, drawerOpen: false }));

        return (
            <Toolbar>
                <IconButton
                    {...{
                        edge: 'start',
                        color: 'inherit',
                        'aria-label': 'menu',
                        'aria-haspopup': 'true',
                        onClick: handleDrawerOpen,
                    }}
                >
                    <MenuIcon />
                </IconButton>

                <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                    <div
                        sx={{
                            padding: '20px 30px',
                        }}
                    >
                        <Menu mobileView={mobileView} />
                        <SocialMediaIcons fill="#12B8B3" />
                    </div>
                </Drawer>
                {/* <Link href="/">
                    <a> */}
                        <Box sx={{ mt: 1, ml: 1 }}>
                            <Link to="/">
                                <a>
                                    <img height="40px" width="180px" src={logo} alt="congress.by" />
                                </a>
                            </Link>
                        </Box>
                    {/* </a>
                </Link> */}
            </Toolbar>
        );
    };

    const Logo = (
        <Typography
            variant="h6"
            component="h1"
            sx={{
                textAlign: 'left',
                marginTop: '10px',
            }}
        >
            {/* <Link href="/">
                <a>
                    <Image height="52px" width="221px" src={logo} alt="congress.by" loading="eager" />
                </a>
            </Link> */}
        </Typography>
    );

    return (
        <header>
            <AppBar
                sx={{
                    backgroundColor: (theme) => theme.palette.primary.light,
                    paddingRight: '79px',
                    paddingLeft: '118px',
                    color: '#555',
                    position: 'fixed',
                    zIndex: '999',
                    '@media (max-width: 900px)': {
                        paddingLeft: 0,
                    },
                }}
            >
                {mobileView ? displayMobile() : displayDesktop()}
            </AppBar>
        </header>
    );
}
