/* eslint-disable react/jsx-filename-extension */
import { Box } from '@mui/material';
import React from 'react';

function FullPageLoader() {
    return (
        <Box
            sx={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.8,
                position: 'absolute',
                background: '#fff'
            }}
        >
            <div className="lds-roller">
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
        </Box>
    );
}

export default FullPageLoader;
