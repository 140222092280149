/* eslint-disable */
import React from 'react';
import { Box, Container, Typography } from '@mui/material';
import theme from '../../theme';

const AboutEvent = ({ text, title }) => {
    return (
        <Box
            sx={{
                pt: 7,
                pb: 7,
                background: theme.palette.background.paper,
            }}
        >
            <Container>
                <Typography variant="h4" sx={{ fontWeight: 700 }}>
                    {title}
                </Typography>
                <Typography
                    variant="subtitle1"
                    dangerouslySetInnerHTML={{ __html: text }}
                    sx={{
                        p: 1,
                    }}
                ></Typography>
            </Container>
        </Box>
    );
};

export default AboutEvent;
