/* eslint-disable */
import React from 'react';
import { Box } from '@mui/material';

const AdressIcon = () => {
    return (
        <Box>
            <svg width="28" height="24" viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M16.1489 9.42236L25.682 11.0767C26.4503 11.2185 26.9697 11.7857 26.9697 12.4569V22.0993C26.9697 22.884 26.2447 23.5173 25.3466 23.5173H15.6079C15.9109 23.5173 16.1489 23.3094 16.1489 23.0447V22.572H25.3466C25.6387 22.572 25.8876 22.364 25.8876 22.0993V12.4569C25.8876 12.2395 25.7145 12.0409 25.4656 11.9937L16.1489 10.3866V9.42236Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M22.1004 14.064C22.4034 14.064 22.6415 14.2719 22.6415 14.5367C22.6415 14.8014 22.4034 15.0093 22.1004 15.0093H19.9363C19.6333 15.0093 19.3953 14.8014 19.3953 14.5367C19.3953 14.2719 19.6333 14.064 19.9363 14.064H22.1004Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M22.1004 16.8999C22.4034 16.8999 22.6415 17.1079 22.6415 17.3726C22.6415 17.6373 22.4034 17.8453 22.1004 17.8453H19.9363C19.6333 17.8453 19.3953 17.6373 19.3953 17.3726C19.3953 17.1079 19.6333 16.8999 19.9363 16.8999H22.1004Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M22.1004 19.7363C22.4034 19.7363 22.6415 19.9443 22.6415 20.209C22.6415 20.4737 22.4034 20.6817 22.1004 20.6817H19.9363C19.6333 20.6817 19.3953 20.4737 19.3953 20.209C19.3953 19.9443 19.6333 19.7363 19.9363 19.7363H22.1004Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M16.149 23.0447C16.149 23.3094 15.9109 23.5173 15.6079 23.5173C15.3049 23.5173 15.0669 23.3094 15.0669 23.0447V22.572V9.80993C15.0669 9.66813 15.1426 9.53578 15.2617 9.44125C15.3915 9.35617 15.5538 9.31835 15.7161 9.34671L16.149 9.42234V10.3866V22.572V23.0447Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M15.067 22.5723V23.045C15.067 23.3097 15.3051 23.5176 15.6081 23.5176H11.2798C11.5828 23.5176 11.8208 23.3097 11.8208 23.045V22.5723H15.067Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M12.3617 5.55615C12.6647 5.55615 12.9027 5.76412 12.9027 6.02881C12.9027 6.2935 12.6647 6.50146 12.3617 6.50146H10.1975C9.89455 6.50146 9.65649 6.2935 9.65649 6.02881C9.65649 5.76412 9.89455 5.55615 10.1975 5.55615H12.3617Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M12.9027 8.86475C12.9027 9.12943 12.6647 9.3374 12.3617 9.3374H10.1975C9.89455 9.3374 9.65649 9.12943 9.65649 8.86475C9.65649 8.60006 9.89455 8.39209 10.1975 8.39209H12.3617C12.6647 8.39209 12.9027 8.60006 12.9027 8.86475Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M12.3617 11.228C12.6647 11.228 12.9027 11.436 12.9027 11.7007C12.9027 11.9654 12.6647 12.1734 12.3617 12.1734H10.1975C9.89455 12.1734 9.65649 11.9654 9.65649 11.7007C9.65649 11.436 9.89455 11.228 10.1975 11.228H12.3617Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M12.3617 14.064C12.6647 14.064 12.9027 14.2719 12.9027 14.5367C12.9027 14.8014 12.6647 15.0093 12.3617 15.0093H10.1975C9.89455 15.0093 9.65649 14.8014 9.65649 14.5367C9.65649 14.2719 9.89455 14.064 10.1975 14.064H12.3617Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M7.49229 14.5367C7.49229 14.8014 7.25424 15.0093 6.95126 15.0093H4.78713C4.48415 15.0093 4.24609 14.8014 4.24609 14.5367C4.24609 14.2719 4.48415 14.064 4.78713 14.064H6.95126C7.25424 14.064 7.49229 14.2719 7.49229 14.5367Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M6.95126 5.55615C7.25424 5.55615 7.49229 5.76412 7.49229 6.02881C7.49229 6.2935 7.25424 6.50146 6.95126 6.50146H4.78713C4.48415 6.50146 4.24609 6.2935 4.24609 6.02881C4.24609 5.76412 4.48415 5.55615 4.78713 5.55615H6.95126Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M6.95126 8.39209C7.25424 8.39209 7.49229 8.60006 7.49229 8.86475C7.49229 9.12943 7.25424 9.3374 6.95126 9.3374H4.78713C4.48415 9.3374 4.24609 9.12943 4.24609 8.86475C4.24609 8.60006 4.48415 8.39209 4.78713 8.39209H6.95126Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M6.95126 11.228C7.25424 11.228 7.49229 11.436 7.49229 11.7007C7.49229 11.9654 7.25424 12.1734 6.95126 12.1734H4.78713C4.48415 12.1734 4.24609 11.9654 4.24609 11.7007C4.24609 11.436 4.48415 11.228 4.78713 11.228H6.95126Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M10.7387 18.3179C10.7387 18.0532 10.4898 17.8452 10.1977 17.8452H6.95147C6.64849 17.8452 6.41044 18.0532 6.41044 18.3179V22.5719H5.32837V18.3179C5.32837 17.5333 6.05336 16.8999 6.95147 16.8999H10.1977C11.0958 16.8999 11.8208 17.5333 11.8208 18.3179V22.5719H10.7387V18.3179Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M6.41044 22.5723H10.7387H11.8208V23.045C11.8208 23.3097 11.5827 23.5176 11.2798 23.5176H5.8694C5.56642 23.5176 5.32837 23.3097 5.32837 23.045V22.5723H6.41044Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
                <path
                    d="M2.89362 1.0184L14.7856 2.59712C15.5755 2.71056 16.149 3.29667 16.149 3.99622V9.42246L15.7162 9.34683C15.5538 9.31847 15.3915 9.35629 15.2617 9.44137C15.1427 9.5359 15.0669 9.66825 15.0669 9.81005V3.99622C15.0669 3.75989 14.8721 3.56136 14.6124 3.52355L2.72049 1.95429C2.68803 1.94484 2.65557 1.94484 2.62311 1.94484C2.49326 1.94484 2.37423 1.98265 2.27684 2.05828C2.14699 2.15281 2.08207 2.2757 2.08207 2.41751V22.0994C2.08207 22.3641 2.33095 22.5721 2.62311 22.5721H5.32828V23.0448C5.32828 23.3095 5.56634 23.5175 5.86932 23.5175H2.62311C1.72499 23.5175 1 22.8841 1 22.0994V2.41751C1 2.00156 1.20559 1.60451 1.5735 1.33982C1.9414 1.06567 2.41751 0.952231 2.89362 1.0184Z"
                    fill="#C4C4C4"
                    stroke="#C4C4C4"
                    stroke-width="0.5"
                />
            </svg>
        </Box>
    );
};

export default AdressIcon;
