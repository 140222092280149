/* eslint-disable */
import React from 'react';
import { Card, CardContent, Typography, Box, Chip, Divider, Avatar, CardActionArea } from '@mui/material';
import { Link, useNavigate } from "react-router-dom";
import Moment from 'react-moment';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import { getMonth } from '../../utils/getMonth';
import theme from '../../theme';

// const A = styled('a')({
//     textDecoration: 'none',
// });

const CustomLink = styled(Link)({
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    height: '100%',
});

const EventCard = ({ event, past }) => {
    const monthVal = moment(event.date, 'YYYY/MM/DD').format('M');
    const month = getMonth(monthVal);

    const navigate = useNavigate()

    const typeEvent = event.typeEvent.map((type) => (
        <Chip
            label={type.title}
            key={type._id}
            sx={{
                background: () => (past ? `#C4C4C4` : `${type.color}`),
                color: '#fff',
                mt: 1,
                mr: 1,
                mb: 0,
            }}
        />
    ));

    const addComma = (index) => {
        if (event.lectures.length - 1 !== index) {
            return ',';
        }
    };
    const lecturesRows = () => {
        return event.lectures.map((lecture, i) => {
            return (
                <Box
                    sx={{
                        marginLeft: '-10px',
                    }}
                    key={lecture._id}
                >
                    <Avatar alt={lecture.fio} src={lecture.photoUrl} loading="eager" />
                </Box>
            );
        });
    };

    return (
        <CustomLink to={`../event/${event._id}`}>
            {/* <A> */}
                <Card
                    sx={{
                        height: '100%',
                        backgroundColor: theme.palette.primary.light,
                        boxShadow: '2px 2px 9px rgba(159, 112, 149, 0.20)',
                        border: '1px solid #f7f2f2',
                        width: '100%',
                    }}
                >
                    <CardActionArea
                        sx={{
                            height: '100%',
                            display: 'flex',
                            alignItems: 'flex-start',
                            alignContent: 'flex-start',
                        }}
                    >
                        <CardContent
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignSelf: 'flex-start',
                                width: '100%',
                                height: '100%',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignSelf: 'flex-start',
                                    width: '100%',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                    }}
                                >
                                    <Box>{typeEvent}</Box>
                                    <Box>{past}</Box>

                                    <Typography gutterBottom variant="subtitle1" component="div" mt={1} ml={2}>
                                        <Moment format="HH:mm">{event.startTime}</Moment>
                                    </Typography>
                                </Box>
                            </Box>

                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignSelf: 'flex-start',
                                    width: '100%',
                                    height: '100% ',
                                    justifyContent: 'space-around',
                                }}
                            >
                                <Typography
                                    gutterBottom
                                    variant="subtitle1"
                                    component="p"
                                    mt={3}
                                    fontWeight={500}
                                    mb={3}
                                >
                                    {event.title}
                                </Typography>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                    <Typography variant="body2" color="text.secondary">
                                        <Moment format="DD">{event.date}</Moment> {month}{' '}
                                        <Moment format="YYYY">{event.date}</Moment>
                                    </Typography>
                                    {event.directionMed.length > 0 && (
                                        <Box
                                            sx={{ display: 'flex', flexDirection: 'row' }}
                                            key={event.directionMed._id}
                                        >
                                            {event.directionMed.map((item) => (
                                                <Box sx={{ ml: 1 }} key={item._id}>
                                                   <img src={item.imageUrl} width="35px" height="35px" />
                                                </Box>
                                            ))}
                                        </Box>
                                    )}
                                </Box>
                                <Divider
                                    sx={{
                                        mt: 2,
                                        mb: 2,
                                    }}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        marginLeft: '10px',
                                    }}
                                >
                                    {event.lectures.map((lecture) => {
                                        return (
                                            <Box
                                                sx={{
                                                    marginLeft: '-10px',
                                                }}
                                                key={lecture._id}
                                            >
                                                <Avatar alt={lecture.fio} src={lecture.photoUrl} loading="eager" />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Box>
                        </CardContent>
                    </CardActionArea>
                </Card>
            {/* </A> */}
        </CustomLink>
    );
};

export default EventCard;
