/* eslint-disable react/jsx-filename-extension */
/* eslint-disable */
import React, { useState, useEffect } from 'react';

import {
    Container,
    Typography,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    ListItemText,
    ListItemIcon,
    Checkbox,
    Button,
    Stack,
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
// import router, { useRouter } from 'next/router';
import MainLayout from '../MainLayout';
import InnerBanner from '../../components/InnerBanner';
import Pagination from '../../components/Pagintion';
import { getAllEvents } from '../../services/Events.service';
import EventCard from '../../components/EventCard';
import { getAllDirectionMed } from '../../services/DirectionMed.service';
import { getAllBanners } from '../../services/Banner.service';
import filterSearch from '../../utils/filterSearch';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import { getAllFaqMain } from '../../services/FAQMain.service';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
    },
    transformOrigin: {
        vertical: 'top',
        horizontal: 'center',
    },
    variant: 'menu',
};

const CustomButton = styled(Button)(({ theme }) => ({
    padding: '12px 30px',
    borderRadius: '24px',
}));

function getStyles(name, selected, theme) {
    return {
        fontWeight:
            selected.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
}

export default function EventsPage() {
    const theme = useTheme();

    const [searchParams, setSearchParams] = useSearchParams();


    const [page, setPage] = React.useState(parseInt(searchParams.get('page')) || 1);
    const [eventsData, setEventsData] = React.useState();
    const [directionMed, setDirectionMed] = React.useState();
    const [medCategory, setMedCategory] = React.useState('all');
    const [banner, setBanner] = React.useState();
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)

    const [selected, setSelected] = useState([]);

    const [sortDate, setSortDate] = useState('all');
    const [variantButton, setVariantButton] = useState({
        all: 'outlined',
        future: 'outlined',
        previous: 'outlined',
    });

    useEffect(() => {
        if (medCategory === 'all') {
            setSelected([]);
        } else {
            setSelected(medCategory);
        }
    }, [medCategory]);

    // const {
    //     query: { term, impact, location },
    // } = useRouter();

    // const initialValue = {
    //     make: query.make || 'all',
    // };

    const isAllSelected = directionMed && directionMed.length > 0 && selected.length === directionMed.length;

    const handleChange = (event) => {
        const { value } = event.target;
        if (value[value.length - 1] === 'all') {
            setSelected(
                selected.length === directionMed.length ? [] : Object.values(directionMed).map((item) => item.title),
            );
            return;
        }
        setSelected(value);
        // setPage(1);
        filterSearch(router, value, sortDate);
    };

    const fetchEvents = async (category) => {
        const data = await getAllEvents(page, category, sortDate);
        setEventsData(data);
    }

    useEffect(() => {
        const category = selected || 'all';
        fetchEvents(category)
    }, [selected, page, sortDate]);

    const handleClickDateChip = (e) => {
        e.preventDefault();
        setSortDate(e.target.value);

        setVariantButton({
            ...Object.keys(variantButton).reduce((reduced, key) => ({ ...reduced, [key]: 'outlined' }), {}),
            [e.target.name]: 'contained',
        });

        // filterSearch(router, null, sortDate);
    };

    const fetchData = async () => {
      try {
            setLoader(true)

          const direction = await getAllDirectionMed();
          setDirectionMed(direction)

          const banner = await getAllBanners();
          setBanner(banner);

          const respFaq = await getAllFaqMain();
          setFaq(respFaq)

      } catch (error) {
          console.log(error);
      } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}

                <InnerBanner banner={banner} />
                <Box sx={{ background: '#F0F0F0' }}>
                    <Container>
                        <Box pt={8} pb={2}>
                            <Typography variant="h4" component="h1" gutterBottom align="left">
                                Наши мероприятия
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: { xs: 'column', md: 'row' },
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    mr: 10,
                                }}
                            >
                                <Typography variant="subtitle1">Медицинская область</Typography>

                                <FormControl sx={{ mt: 2, mb: 5, width: 300 }}>
                                    <InputLabel id="demo-multiple-name-label">Область медицины</InputLabel>
                                    <Select
                                        labelId="mutiple-select-label"
                                        multiple
                                        value={selected}
                                        onChange={handleChange}
                                        renderValue={(selected) => selected.join(', ')}
                                        MenuProps={MenuProps}
                                        label="Область медицины"
                                        sx={{ borderRadius: '30px' }}
                                    >
                                        <MenuItem value="all">
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={isAllSelected}
                                                    indeterminate={
                                                        selected.length > 0 && selected.length < directionMed.length
                                                    }
                                                />
                                            </ListItemIcon>
                                            <ListItemText primary="Выбрать все" />
                                        </MenuItem>
                                        {directionMed &&
                                            directionMed.map((name) => (
                                                <MenuItem key={name.title} value={name.title}>
                                                    <ListItemIcon>
                                                        <Checkbox checked={selected.indexOf(name.title) > -1} />
                                                    </ListItemIcon>
                                                    <ListItemText primary={name.title} />
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <Typography variant="subtitle1">По дате</Typography>

                                <Stack
                                    direction="row"
                                    spacing={2}
                                    sx={{ fontSize: '18px', mt: 2, mb: 5, borderRadius: '30px' }}
                                >
                                    <CustomButton
                                        variant={variantButton.all}
                                        name="all"
                                        value="all"
                                        onClick={handleClickDateChip}
                                        color="primary"
                                    >
                                        Все
                                    </CustomButton>
                                    <CustomButton
                                        variant={variantButton.future}
                                        name="future"
                                        value="future"
                                        onClick={handleClickDateChip}
                                        color="primary"
                                    >
                                        Предстоящие
                                    </CustomButton>
                                    <CustomButton
                                        variant={variantButton.previous}
                                        name="previous"
                                        value="previous"
                                        onClick={handleClickDateChip}
                                        color="primary"
                                    >
                                        Прошедшие
                                    </CustomButton>
                                </Stack>
                            </Box>
                        </Box>

                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                            }}
                        >
                            {eventsData &&
                                eventsData.events?.length > 0 &&
                                eventsData.events.map((event) => (
                                    <Box
                                        key={event._id}
                                        sx={{
                                            position: 'relative',

                                            minWidth: { xs: '100%', md: '32%' },
                                            width: { xs: '100%', md: '32%' },
                                            paddingRight: '10px',
                                            pr: 3,
                                            pb: 3,
                                        }}
                                    >
                                        <EventCard event={event} key={event._id} />
                                    </Box>
                                ))}
                        </Box>

                        <Pagination url="events" pageCurrent={page} pages={eventsData?.pages} setPage={setPage} />
                    </Container>
                </Box>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
}
