/* eslint-disable no-param-reassign */
import axios from 'axios';

const client = axios.create({
    baseURL: 'https://congress-backend.nw.r.appspot.com/api/',
    headers: {
        'Content-type': 'application/json',
    },
});

// eslint-disable-next-line import/prefer-default-export
export const get = (url, params) => {
    return client.get(url, params);
};

export const post = (url, body, token = '') => {
    const config = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
    return client.post(url, body, config);
};

// client.interceptors.request.use((config) => {
//     config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
//     return config;
// });

export default client;
