/* eslint-disable */
import React from 'react';

const InstagramSvg = ({ fill }) => (
    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.84 27.9525C22.5589 27.9525 28.8163 21.6951 28.8163 13.9763C28.8163 6.25739 22.5589 0 14.84 0C7.12116 0 0.86377 6.25739 0.86377 13.9763C0.86377 21.6951 7.12116 27.9525 14.84 27.9525ZM11.8132 5.94947C9.05179 5.94947 6.81321 8.18806 6.81321 10.9495V16.8147C6.81321 19.5761 9.05179 21.8147 11.8132 21.8147H17.6784C20.4399 21.8147 22.6784 19.5761 22.6784 16.8147V10.9495C22.6784 8.18805 20.4398 5.94947 17.6784 5.94947H11.8132ZM18.7121 13.8821C18.7121 16.0726 16.9364 17.8484 14.7458 17.8484C12.5553 17.8484 10.7795 16.0726 10.7795 13.8821C10.7795 11.6916 12.5553 9.9158 14.7458 9.9158C16.9364 9.9158 18.7121 11.6916 18.7121 13.8821ZM17.39 13.8821C17.39 15.3424 16.2062 16.5263 14.7458 16.5263C13.2855 16.5263 12.1016 15.3424 12.1016 13.8821C12.1016 12.4217 13.2855 11.2379 14.7458 11.2379C16.2062 11.2379 17.39 12.4217 17.39 13.8821ZM20.1995 9.91579C20.1995 10.4634 19.7555 10.9074 19.2079 10.9074C18.6603 10.9074 18.2163 10.4634 18.2163 9.91579C18.2163 9.36816 18.6603 8.92422 19.2079 8.92422C19.7555 8.92422 20.1995 9.36816 20.1995 9.91579Z"
            fill={fill}
            fillOpacity="0.7"
        />
    </svg>
);

export default InstagramSvg;
