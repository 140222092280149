/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { Box, Chip, Container, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import Moment from 'react-moment';
import moment from 'moment';
import MainLayout from '../MainLayout';
import { getSpecProjects } from '../../services/SpecProjects.service';
import { getMonth } from '../../utils/getMonth';
import AboutEvent from '../../components/AboutEvent';
import { getAllFaqMain } from '../../services/FAQMain.service';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import CalendarIcon from '../../components/SvgImages/calendarIcon';
import { useParams } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader';

const CustomColumn = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    padding: '70px 0px',
});

export const SpecprojectsInnerPage = () => {

    const [specProject, setSpecProject] = useState([])
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)

    const params = useParams();

    const monthVal = moment(specProject.createAt, 'YYYY/MM/DD').format('M');
    const month = getMonth(monthVal);
    
    const fetchData = async () => {
      try {
        setLoader(true)

        const specProjectResponse = await getSpecProjects(params.id);
        setSpecProject(specProjectResponse)

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

      } catch (error) {
          console.log(error);
      } finally {
        setLoader(false)
      }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}

                <Box sx={{
                    marginTop: { xs: '55px', md: '80px' },
                    width: "100%"
                }}>
                    <img src={specProject?.banner} alt="" width="100%" />
                </Box>
                {(specProject?.directionMed?.length > 0 || specProject?.showDate) && (
                    <Container>
                        <CustomColumn>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                }}
                            >
                                {specProject?.directionMed?.map((item) => (
                                    <Chip key={item._id} label={item.title} variant="outlined" sx={{ mr: 2 }} />
                                ))}
                            </Box>
                            {specProject?.showDate && (
                                <Box sx={{ mt: 4, display: 'flex', flexDirection: 'row' }}>
                                    <CalendarIcon />
                                    <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                        {' '}
                                        <Moment format="DD">{specProject?.createAt}</Moment> {month}{' '}
                                        <Moment format="YYYY">{specProject?.createAt}</Moment>
                                    </Typography>
                                </Box>
                            )}
                        </CustomColumn>
                    </Container>
                )}

                <AboutEvent text={specProject?.description} title="О проекте" />

                <Container>
                    <Typography
                        variant="subtitle1"
                        sx={{ ml: 2 }}
                        dangerouslySetInnerHTML={{ __html: specProject?.text }}
                    />
                </Container>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
};
