const filterSearch = (router, category, sortDate) => {
    const path = router.pathname;
    const { query } = router;

    if (category) {
        query.category = category;
    }

    if (sortDate) {
        query.sortDate = sortDate;
    }

    router.push(
        {
            pathname: path,
            query,
        },
        undefined,
        { shallow: true },
    );
};

export default filterSearch;
