/* eslint-disable */
import React, {useState, useEffect} from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import MainLayout from '../MainLayout';
import InnerBanner from '../../components/InnerBanner';
import { getPublication } from '../../services/Publications.service';
import theme from '../../theme';
import { getAllFaqMain } from '../../services/FAQMain.service';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import RecommendationILibraryInner from '../../components/RecommendationILibraryInner';
import { getAllRecommendation } from '../../services/Recommendation.service';
import { useParams } from 'react-router-dom';
import FullPageLoader from '../../components/FullPageLoader';

export const PublicationsInnerPage = () => {
    const [publication, setPublication] = useState([])
    const [recommendation, setRecommendation] = useState();
    const [faq, setFaq] = useState([])
    const [loader, setLoader] = useState(false)

    const params = useParams();
    
    const fetchData = async () => {
      try {
        setLoader(true)

        const publicationResponse = await getPublication(params.id);
        setPublication(publicationResponse)

        const recommendationData = await getAllRecommendation()
        setRecommendation(recommendationData.filter((el) => el.item !== null))

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

      } catch (error) {
          console.log(error);
      } finally {
        setLoader(false)
      }
    }

    useEffect(() => {
      fetchData()
    }, [])

    return (
        <>
            <MainLayout>
                {loader && <FullPageLoader />}

                <InnerBanner>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                            width: '100%',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: { xs: '100%', md: '70%' },
                                minWidth: '70%',
                            }}
                        >
                            <Typography
                                variant="h3"
                                sx={{
                                    fontWeight: 700,
                                    fontSize: { xs: '24px', md: '40px' },
                                    color: theme.palette.primary.light,
                                }}
                            >
                                {publication?.title}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: { xs: '100%', md: '70%' },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: { xs: 'flex-start', md: 'center' },
                                alignSelf: 'flex-end',
                            }}
                        />
                    </Box>
                </InnerBanner>

                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ my: 6, width: { xs: '100%', md: '80%' } }}
                            dangerouslySetInnerHTML={{ __html: publication?.text }}
                        />
                        <Box>{recommendation?.length > 0 && <RecommendationILibraryInner data={recommendation} />}</Box>
                    </Box>
                </Container>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
};
