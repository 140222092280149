/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import moment from 'moment';
import MainLayout from '../MainLayout';
import { getAdSpecproject } from '../../services/ADSpecProjects.service';

import { getMonth } from '../../utils/getMonth';
import { getAllFaqMain } from '../../services/FAQMain.service';
import SubscribeNews from '../../components/SubscribeNews';
import MainFaq from '../../components/FAQMain';
import { getAllRecommendation } from '../../services/Recommendation.service';
import RecommendationILibraryInner from '../../components/RecommendationILibraryInner';
import { getAdSpecprojects } from '../../services/ADSpecProjects.service';
import { useAuthSpecProjects } from '../../hooks/useAuthSpecProjects';
import { Store } from '../../contexts/Store';
import { useNavigate, useParams } from 'react-router-dom';


export const AdSpecprojectsInnerPage = () => {
    const { state, dispatch } = React.useContext(Store);
    const navigate = useNavigate();
    const params = useParams();

    const { logout } = useAuthSpecProjects();

    const [item, setItem] = React.useState()
    const [cabInfo, setCabInfo] = React.useState()

    const [recommendation, setRecommendation] = useState();
    const [faq, setFaq] = useState([])

    const monthVal = moment(item?.createAt, 'YYYY/MM/DD').format('M');
    const month = getMonth(monthVal);

    // const params = useParams();
    
    const fetchData = async () => {
      try {
        const itemData = await getAdSpecproject(params.id);
        setItem(itemData)

        const items = await getAdSpecprojects({code: state.user.code})
        setCabInfo(items && items[0].cabinet)

        const recommendationData = await getAllRecommendation()
        setRecommendation(recommendationData.filter((el) => el.item !== null))

        const respFaq = await getAllFaqMain();
        setFaq(respFaq)

      } catch (error) {
          console.log(error);
      }
    }

    useEffect(() => {
      fetchData()
    }, [])

    const logoutHandler = () => {
        logout();
        navigate('/login-specprojects')
    }

    // React.useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const items = await getAdSpecprojects({code: state.user.code})
    //             setCabInfo(items && items[0].cabinet)
    //         } catch (error) {
    //             console.log(error);
    //         }
    //     }
    //     fetchData()
    // }, []);

    return (
        <>
            <MainLayout>
                <Box sx={{position: 'relative', pt: { xs: 7, md:10 }}}>
                    {cabInfo?.backgroundImg?.length > 0 && (
                        <img src={cabInfo.backgroundImg} alt='' width='100%'  />
                    )}
                        <Button 
                            variant="outlined"
                            
                            sx={{ 
                                position: 'absolute', 
                                color:"#5C5C5C", 
                                background: 'rgba(255,255,255, 0.6)', 
                                top: { xs: '60px', md:  '90px' }, 
                                right: '15px', 
                                "&:hover": {
                                    background: 'rgba(255,255,255, 0.7)', 
                                }}} 
                            onClick={logoutHandler}
                        >
                            Выйти
                        </Button>
                </Box>
                <Container>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: { xs: 'column', md: 'row' },
                        }}
                    >
                        <Typography
                            variant="subtitle1"
                            sx={{ my: 6, width: { xs: '100%', md: '80%' } }}
                            dangerouslySetInnerHTML={{ __html: item?.text }}
                        />
                        <Box>{recommendation?.length > 0 && <RecommendationILibraryInner data={recommendation} />}</Box>
                    </Box>
                </Container>
                <SubscribeNews />
                <Container>
                    <MainFaq faq={faq} />
                </Container>
            </MainLayout>
        </>
    );
};
