/* eslint-disable import/prefer-default-export */
import { get } from '../http';

export const getAllRecommendation = async () => {
    const response = await get(`recommendation/`);

    if (response.data[0].recommendation) {
        return response.data[0].recommendation;
    }
    return null;
};
