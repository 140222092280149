import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            // main: '#04B3CC',
            main: '#12B8B3',
            dark: '#5C5C5C',
            light: '#fff',
            // light: '#757ce8',
            // main: '#3f50b5',
            // dark: '#002884',
            contrastText: '#F7F7F7',
        },
        secondary: {
            main: '#19857b',
            // dark: '#dbdbdb',
            light: '#dddddd',
        },
        background: {
            paper: '#F0F0F0',
        },
        error: {
            main: red.A400,
        },
    },
});

export default theme;
