/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable */
import * as React from 'react';
import usePagination from '@mui/material/usePagination';
// import { useRouter } from 'next/router';
// import Link from 'next/link';
import { styled } from '@mui/material/styles';
import theme from '../../theme';
import { useNavigate, Link } from 'react-router-dom';

const Nav = styled('nav')({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '20px 10px',
});

const List = styled('ul')({
    listStyle: 'none',
    padding: 0,
    margin: 0,
    display: 'flex',
});

const Pagination = styled('button')({
    background: theme.palette.primary.contrastText,
    border: '1px solid #bdbcbc',
    borderRadius: '50%',
    margin: 5,
    padding: 5,
    fontSize: '14px',
    width: '40px',
    height: '40px',
    cursor: 'pointer',
});

const PaginationButton = styled(Link)({
    cursor: 'pointer',
});

const PaginationComponent = ({ url, pageCurrent, pages, setPage }) => {
    const { items } = usePagination({
        count: pages,
        page: pageCurrent
    });

    // const router = useRouter();
    const navigate = useNavigate();

    return (
        <Nav>
            <List>
                {items.map(({ page, type, selected, onClick, ...item }, index) => {
                    let children = null;
                    // setPage(page - 1);

                    if (type === 'start-ellipsis' || type === 'end-ellipsis') {
                        children = '…';
                    } else if (type === 'page') {
                        children = (
                            <Pagination
                                type="button"
                                style={{
                                    background: selected
                                        ? theme.palette.secondary.light
                                        : theme.palette.primary.contrastText,
                                }}
                                onClick={() => {
                                    navigate(`?page=${page}`)
                                    setPage(page);
                                }}
                                {...item}
                            >
                                {page}
                            </Pagination>
                        );
                    } else {
                        children = (
                            <Pagination page={pageCurrent} type="button" {...item}>
                                {type && type === 'next' ? '>' : '<'}
                            </Pagination>
                        );
                    }

                    return (
                        <li key={index}>
                            <PaginationButton scroll={false} to={`?page=${page}`}>
                              {children}
                            </PaginationButton>
                        </li>
                    );
                })}
            </List>
        </Nav>
    );
};

export default PaginationComponent;
