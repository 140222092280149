/* eslint-disable */
import * as React from 'react';
import MainLayout from '../MainLayout';
import Container from '@mui/material/Container';
import RegistrForm from '../../components/RegistrForm';


export default function RegistrationSpecProjectsPage() {
    const [value, setValue] = React.useState('1');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <MainLayout>
                <Container component="main" maxWidth="sm" sx={{mt: 12, mb: 10 }}>
                    <RegistrForm />
                </Container>
            </MainLayout>
        </>
    );
}
