/* eslint-disable */
import * as React from 'react';
import MainLayout from '../MainLayout';
import Container from '@mui/material/Container';
import LoginForm from '../../components/LoginForm';

export default function LoginSpecProjectsPage() {
    return (
        <>
            <MainLayout>
                <Container component="main" maxWidth="sm" sx={{mt: 12, mb: 10, height: '65vh' }}>
                    <LoginForm />
                </Container>
            </MainLayout>
        </>
    );
}
