/* eslint-disable */
import * as React from 'react';
import MainLayout from '../MainLayout';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import { Store } from '../../contexts/Store';
import { useAuthSpecProjects } from '../../hooks/useAuthSpecProjects';
import { getAdSpecprojects } from '../../services/ADSpecProjects.service';
import { Button } from '@mui/material';
import LibraryCard from '../../components/LibraryCard';
import { useNavigate } from 'react-router-dom';


export default function AdSpecprojectsPage() {
    const { state, dispatch } = React.useContext(Store);
    const { logout, checkAuth } = useAuthSpecProjects();
    const [data, setData] = React.useState()
    const [cabInfo, setCabInfo] = React.useState()

    const navigate = useNavigate()

    const logoutHandler = () => {
        logout();
        navigate('/login-specprojects')
    }

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                // const cab = await getAdSpecprojectsCab({code: state.user.code})
                const items = await getAdSpecprojects({code: state.user.code})
                setData(items)
                setCabInfo(items && items[0].cabinet)
            } catch (error) {
                console.log(error);
            }
        }
        fetchData()
    }, []);

    return (
        <>
            <MainLayout>
                <Box sx={{position: 'relative', pt: { xs: 7, md:10 }}}>
                    {cabInfo?.backgroundImg.length > 0 && (
                        <img src={cabInfo.backgroundImg} alt='' width='100%'  />
                    )}
                        <Button 
                            variant="outlined"
                            
                            sx={{ 
                                position: 'absolute', 
                                color:"#5C5C5C", 
                                background: 'rgba(255,255,255, 0.6)', 
                                top: { xs: '60px', md:  '90px' }, 
                                right: '15px', 
                                "&:hover": {
                                    background: 'rgba(255,255,255, 0.7)', 
                                }}} 
                            onClick={logoutHandler}
                        >
                            Выйти
                        </Button>
                </Box>
                <Box>
                    <Container>
                        <Box
                            sx={{
                                display: 'flex',
                                flexWrap: 'wrap',
                                flexDirection: 'column',
                                pt: 5,
                                pb: 5
                            }}
                        >

                            {data?.length > 0 && data.map((item) => (
                                <Box key={item._id} sx={{ my: 1 }}>
                                    <LibraryCard data={item} link="ad-specprojects" />
                                </Box>
                            ))}
                        </Box>
                    </Container>
                </Box>
            </MainLayout>
        </>
    );
}