import { get } from '../http';

// eslint-disable-next-line import/prefer-default-export
export const getAllEvents = async (page, category, sortDate) => {
    const params = {
        params: {
            type: 'all',
            page,
            category,
            sortDate,
        },
    };

    const response = await get(`events/events`, params);
    return response.data.allEvents;
};

export const getTenPastEvents = async () => {
    const params = {
        params: {
            limit: 10,
            type: 'past',
        },
    };
    const response = await get(`events/events/`, params);
    return response.data.pastTenEvents;
};

export const getTenFutureEvents = async () => {
    const params = {
        params: {
            limit: 10,
            type: 'future',
        },
    };
    const response = await get(`events/events/`, params);
    return response.data.futureTenEvents;
};

export const getEvent = async (id) => {
    const response = await get(`events/events/${id}`);
    return response.data;
};
